import React, { useState } from "react"
import { useMutation } from "urql"

import { useToast } from "../../contexts/ToastContext"

import { CheckBox } from "./Inputs"
import { Link } from "./Links"
import { AnimatedModal } from "./Modal"

export const ACCEPT_TERMS = `
  mutation AcceptTerms {
    acceptTerms {
      result
    }
  }
`

const AcceptTermsModal = ({ showModal }) => {
  const [visible, setVisible] = useState(showModal)
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [{ fetching }, acceptTerms] = useMutation(ACCEPT_TERMS)

  const { showToast } = useToast()

  const handleSave = () => {
    acceptTerms().then((res) => {
      if (res.data?.acceptTerms?.result === "success") {
        setVisible(false)
        showToast("Terms and conditions accepted")
      } else {
        console.error(res.error) // eslint-disable-line no-console
        showToast({ content: "Error accepting terms and conditions", type: "error" })
      }
    })
  }

  return (
    <AnimatedModal
      visible={visible}
      hideModal={() => {}}
      showFooter={true}
      hideCloseButton={true}
      hideCancelButton={true}
      actionButtonCopy="Accept"
      saveDisabled={!termsAccepted || fetching}
      onSave={handleSave}>
      <div className="p-6">
        <h2 className="mb-4 text-xl font-bold">Terms and Conditions</h2>
        <p>
          Heal.me is now HIPAA and PIPEDIA compliant. Please read and accept our updated <Link href="/terms">terms and conditions</Link>,{" "}
          <Link href="/privacy">privacy policy</Link>, and{" "}
          <Link href="/business_associate_agreement">business associate agreement</Link> to proceed.
        </p>
        <div className="mt-4">
          <CheckBox
            id="acceptTerms"
            checked={termsAccepted}
            onChange={() => setTermsAccepted(!termsAccepted)}
            label="I accept the terms and conditions, privacy policy, and business associate agreement."
          />
        </div>
      </div>
    </AnimatedModal>
  )
}

export default AcceptTermsModal
