import React from "react"
import { twMerge } from "tailwind-merge"

import { DEFAULT_HIGHLIGHT_CLASSES } from "../WebsiteBuilder/WebsiteBuilderPage"

const ThemeImage = ({ id, src, alt, highlight, className }) => (
  <div id={id} className={twMerge("my-20 scroll-mt-24 text-center md:mb-10 md:mt-16", className)}>
    <img
      className={twMerge(
        "mx-auto h-[448px] w-[757px] object-cover md:h-60 md:w-full",
        highlight ? DEFAULT_HIGHLIGHT_CLASSES : ""
      )}
      alt={alt}
      src={src}
    />
  </div>
)

export default ThemeImage
